import React from 'react';
import { Link, graphql } from 'gatsby';
import { shape, array, int } from 'prop-types';
import Layout from '../components/layout';
import Img from 'gatsby-image';
import styled from 'styled-components';
// import SEO from '../components/seo';

const postsPerPage = 5;

const PostList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > li {
    list-style-type: none;
    padding: 2rem 0;
    border-bottom: 1px dotted rgba(242, 19, 104, 0.4);
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: 0;
    }
    a {
      display: block;
      color: #e0e0e0;
      text-decoration: none;
      &:hover {
        color: #f21368;
      }
      .gatsby-image-wrapper {
        margin: 2rem;
      }
      article {
        text-shadow: rgba(16, 16, 16, 1) 1px 2px 2px;
        /* background: rgba(16, 16, 16, 0.7); */
        padding: 2rem;
        h1 {
          /* font-size: 2.8rem; */
          margin-bottom: 4px;
        }
        h2 {
          font-size: 1.9rem;
        }
        h3 {
          font-size: 1.8rem;
        }
        p {
          font-size: 1.8rem;
          color: #f21368;
        }
      }

      &:hover {
        article p {
          color: #e0e0e0;
        }
      }
    }
  }
`;

const PaginationNav = styled.nav`
  & > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content: space-between;
    align-items: flex-end;

    & a {
      color: #f21368;

      &:hover {
        color: #e0e0e0;
      }
    }

    & > li {
      &.previous {
        align-self: flex-start;
      }

      &.next {
        align-self: flex-end;
      }
    }
  }
`;

const calcPageDetails = (pageContext, allPublished) => {
  const { currentPage, numPages } = pageContext;

  if (!currentPage && !numPages) {
    // if pageContext is null we're just building the index page, not the blog template
    // we'll assume we're on the first page - safe assumption
    // allPublished will have the total count, but need postsPerPage for context
    // if total count is greater than pageSize then we want a nav link to next page
    return {
      isFirst: true,
      isLast: allPublished.length <= postsPerPage,
      prevPage: '/', // doesn't matter here...
      nextPage: '2',
    };
  }

  return {
    isFirst: currentPage === 1,
    isLast: currentPage === numPages,
    prevPage: currentPage - 1 === 1 ? '/' : (currentPage - 1).toString(),
    nextPage: (currentPage + 1).toString(),
  };
};

const navLinks = (pageContext, allPublished) => {
  const { nextPage, prevPage, isFirst, isLast } = calcPageDetails(pageContext, allPublished);
  return (
    !(isFirst && isLast) && (
      <PaginationNav>
        <ul>
          {!isFirst && (
            <li className="previous">
              <Link to={prevPage} rel="prev">
                ← Previous Page
              </Link>
            </li>
          )}
          {!isLast && (
            <li className="next">
              <Link to={nextPage} rel="next">
                Next Page →
              </Link>
            </li>
          )}
        </ul>
      </PaginationNav>
    )
  );
};

const IndexPage = ({
  pageContext,
  data: {
    allMarkdownRemark: { edges },
    allPublished: { edges: allPublished },
  },
}) => {
  return (
    <Layout isWide={true}>
      <PostList>
        {edges.map(({ node }, index) => {
          const itemClassName = node.frontmatter.image ? `with-image` : ``;
          return (
            <li key={index} className={itemClassName}>
              <Link to={node.fields.slug} partiallyActive={true}>
                {node.frontmatter.image && (
                  <div className="image">
                    <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                  </div>
                )}
                <article>
                  <h1>{node.frontmatter.title}</h1>
                  <h2>{node.frontmatter.date}</h2>
                  <p>{node.excerpt}</p>
                  <h3>{node.fields.readingTime.text}</h3>
                </article>
              </Link>
            </li>
          );
        })}
      </PostList>
      {navLinks(pageContext, allPublished)}
    </Layout>
  );
};

IndexPage.propTypes = {
  pageContext: shape({
    currentPage: int,
    numPages: int,
  }).isRequired,
  data: shape({
    allMarkdownRemark: shape({
      edges: array.isRequired,
    }).isRequired,
    everything: shape({
      edges: array.isRequired,
    }),
  }).isRequired,
};

export default IndexPage;

export const listQuery = graphql`
  query ListQuery($skip: Int = 0, $limit: Int = 5) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, fields___slug] }
      filter: { frontmatter: { draft: { in: [false, null] } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            image {
              childImageSharp {
                resize(width: 1500, height: 1500) {
                  src
                }
                fluid(maxWidth: 786, maxHeight: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allPublished: allMarkdownRemark(filter: { frontmatter: { draft: { in: [false, null] } } }) {
      edges {
        node {
          fields {
            slug
          }
        }
      }
    }
  }
`;
